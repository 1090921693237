<template>
  <v-container>
    <page-title :title="title"></page-title>
    <customForm
      v-if="initDone"
      :slotComponents="slotComponents"
      :handlerSubmit="updateApi"
      :value="readData"
      :commonProps="{stage, readApi, changeStage}"
    >
      <template v-slot:action="{ defaultKey }">
        <cartBtns
          :defaultKey="defaultKey"
          :changeStage="changeStage"
          :stage="stage"
          :storeId="storeId"
          :providerId="providerId"
        ></cartBtns>
      </template>
    </customForm>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import customForm from "@/components/form/custom/customForm.vue";
import cartBtns from "./cartBtns.vue"
import qs from 'qs'

export default {
  components: {
    customForm,
    cartBtns,
  },
  
  data: () => ({
    stage: 1,
    initDone: false,
  }),
  computed: {
    title() {
      if(this.stage == 0) return '購物車'
      if(this.stage == 1) return '購物車'
      if(this.stage == 2) return '訂單確認'
      return '訂單已送出'
    },
    providerId() {
      return this.$route.params.providerId
    },
    storeId() {
      return this.$store.getters[`member/storeId`]
    },
    slotComponents() {
      return [
        {
          values : ['*'],
          component : () => import('./info.vue')
        },
      ];
    },
    readData() {
      return this.$store.getters['cart/cart']
    },
  },
  async created () {
    await this.beforeFormInit();
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.readApi()
      ])
      this.initDone = true
    },
    async readApi() {
      const res =  await this.$api.collection.cartApi.read(this.storeId, this.providerId)
      this.$store.dispatch('cart/setCart', res)
      return res
    },
    async updateApi(data) {
      this.$root.gaLogEvent('商店_點擊_結帳')
      this.$store.dispatch('loading/active')
        try {
          const res = await this.$api.collection.orderApi.create(this.storeId, this.providerId, qs.stringify(data))
          this.$snotify.success('建立訂單成功')
          this.$router.replace({name: 'store-order-detail', params: {providerId: this.providerId, orderId: res.order_no}})
        } catch(err) {
          console.error(err)
          this.$snotify.error('建立訂單失敗')
        } finally {
          this.$store.dispatch('loading/close')
          this.readApi()
        }
    },
    changeStage(stage) {
      this.stage = stage
    },
  },
}
</script>