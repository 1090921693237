var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.apiData.length > 0 &&
      (_vm.mergeFreeData.freezing.main_island.amount !== 0 ||
        _vm.mergeFreeData.freezing.outlying_islands.amount !== 0 ||
        _vm.mergeFreeData.normal.main_island.amount !== 0 ||
        _vm.mergeFreeData.normal.outlying_islands.amount !== 0 ||
        _vm.mergeFreeData.refrigeration.main_island.amount !== 0 ||
        _vm.mergeFreeData.refrigeration.outlying_islands.amount !== 0)
  )?_c('div',{staticClass:"mt-20"},[_c('h3',{staticClass:"mb-4"},[_vm._v("運費規則說明：")]),_c('ol',{staticClass:"fr-ol"},[_vm._l((_vm.freightText),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item))])}),_c('li',[_vm._v("以上資訊供參考，實際情況以供應商計價為準。")])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }