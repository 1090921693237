var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.stage == 0)?[_c('v-row',[_c('v-col',[_c('custom-form-action-btn',{key:_vm.stage,attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'back',
          },"label":"繼續購物","color":"primary"}})],1)],1)]:_vm._e(),(_vm.stage == 1)?[_c('v-row',[_c('v-col',[_c('custom-form-action-btn',{key:_vm.stage,attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'click',
            handler: _vm.goBackToList,
          },"label":"繼續購物","outlined":"","color":"primary"}})],1),_c('v-col',[_c('custom-form-action-btn',{key:_vm.stage,attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'click',
            handler: function () { return _vm.changeStage(2); },
          },"label":"確認","color":"primary"}})],1)],1)]:_vm._e(),(_vm.stage == 2)?[_c('v-row',[_c('v-col',[_c('custom-form-action-btn',{key:_vm.stage,attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'click',
            handler: function () { return _vm.changeStage(1); },
          },"label":"返回","outlined":"","color":"primary"}})],1),_c('v-col',[_c('custom-form-action-btn',{key:_vm.stage,attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'submit',
          },"label":"確認","color":"primary"}})],1)],1)]:_vm._e(),[_c('freight-rules')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }