<template>
  <div>
    <!-- 空 -->
    <template v-if="stage == 0">
      <v-row>
        <v-col>
          <custom-form-action-btn
            :key="stage"
            :defaultKey="defaultKey"
            :action="{
              key: 'back',
            }"
            label="繼續購物"
            color="primary"
          ></custom-form-action-btn>
        </v-col>
      </v-row>
    </template>
    <!-- 購物車 -->
    <template v-if="stage == 1">
      <v-row>
        <v-col>
          <custom-form-action-btn
            :key="stage"
            :defaultKey="defaultKey"
            :action="{
              key: 'click',
              handler: goBackToList,
            }"
            label="繼續購物"
            outlined
            color="primary"
          ></custom-form-action-btn>
        </v-col>

        <v-col>
          <custom-form-action-btn
            :key="stage"
            :defaultKey="defaultKey"
            :action="{
              key: 'click',
              handler: () => changeStage(2),
            }"
            label="確認"
            color="primary"
          ></custom-form-action-btn>
        </v-col>
      </v-row>
    </template>
    <!-- 確認訂單 -->
    <template v-if="stage == 2">
      <v-row>
        <v-col>
          <custom-form-action-btn
            :key="stage"
            :defaultKey="defaultKey"
            :action="{
              key: 'click',
              handler: () => changeStage(1),
            }"
            label="返回"
            outlined
            color="primary"
          ></custom-form-action-btn>
        </v-col>

        <v-col>
          <custom-form-action-btn
            :key="stage"
            :defaultKey="defaultKey"
            :action="{
              key: 'submit',
            }"
            label="確認"
            color="primary"
          ></custom-form-action-btn>
        </v-col>
      </v-row>
    </template>
    <template>
      <freight-rules></freight-rules>
    </template>
  </div>
</template>

<script lang="babel" type="text/babel">
import ItemMixin from "@/components/form/custom/ItemMixin.js";
import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue";
import FreightRules from "./freightRules.vue";

export default {
  components: {
    customFormActionBtn,
    "freight-rules": FreightRules,
  },
  mixins: [ItemMixin],
  props: {
    defaultKey: String,
    changeStage: Function,
    stage: Number,
    storeId: String,
    providerId: String,
  },
  data: () => ({
    refresh: 0,
  }),
  mounted() {
    this.$root.gaLogEvent('商店_購物車頁')
  },
  async created() {},
  watch: {},
  computed: {},
  methods: {
    goBackToList() {
      this.$router.push({
        name: "store-product-list",
        params: {
          providerId: this.providerId,
          auth: "auth",
        },
      });
    },
  },
};
</script>
